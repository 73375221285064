<script setup lang="ts">
const { keepWatchingData, isKeepWatchigLoading } = useCourseOverview();
</script>

<template>
  <div v-if="isKeepWatchigLoading" class="text-center">
    <PrimeProgressSpinner class="h-8 w-8" />
  </div>

  <section v-if="keepWatchingData?.length" class="space-y-4 sm:text-sm">
    <header class="flex items-center justify-between">
      <h2 class="text-xl font-semibold text-bluegray-900">Oglądaj dalej</h2>
    </header>

    <CourseDetailCard :keepWatching="keepWatchingData[0]" />
  </section>
</template>
