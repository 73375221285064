<script setup lang="ts">
const { data: informations } = useInformations();
const { data: news, isLoading: isNewsLoading } = useNews();
const { data: jobs } = useJobOffers();
const { data: user } = useUser();

function getUnseenCount(items: { seen: boolean }[]) {
  return items.reduce((acc, item) => acc + (!item.seen ? 1 : 0), 0);
}

const unseenNewsCount = computed(() =>
  news.value ? getUnseenCount(news.value) : null,
);

const unseenJobsCount = computed(() =>
  jobs.value ? getUnseenCount(jobs.value) : null,
);

const unseenInformationsCount = computed(() =>
  informations.value ? getUnseenCount(informations.value) : null,
);
</script>

<template>
  <PrimeTabs value="0" lazy>
    <PrimeTabList
      class="[&_[role='tablist']]:hide-scrollbar text-tabview-header-color [&_[role='tablist']]:flex [&_[role='tablist']]:overflow-x-auto"
    >
      <PrimeTab id="announcements" value="0" class="flex items-center">
        <span class="mr-1 font-semibold">Aktualności</span>

        <AnnouncementUnreadBadge type="news" :events="news ? news : null" />
      </PrimeTab>

      <PrimeTab value="1" class="flex items-center">
        <span class="mr-1 font-semibold">Oferty pracy</span>

        <AnnouncementUnreadBadge type="offer" :events="jobs ? jobs : null" />
      </PrimeTab>

      <PrimeTab
        v-if="user?.zmidAssoc?.is_active"
        value="2"
        class="flex items-center"
      >
        <span class="mr-1 font-semibold">Informacje dla członków</span>

        <AnnouncementUnreadBadge
          type="information"
          :events="informations ? informations : null"
        />
      </PrimeTab>
    </PrimeTabList>

    <PrimeTabPanels class="min-h-[95vh] pt-6">
      <PrimeTabPanel class="space-y-3" value="0">
        <div v-if="isNewsLoading" class="text-center">
          <PrimeProgressSpinner class="h-6 w-6" />
        </div>

        <AnnouncementCard
          :key="item.id"
          :annoucement="item"
          v-for="item in news"
          type="news"
        />
      </PrimeTabPanel>

      <PrimeTabPanel class="space-y-3" value="1">
        <LazyJobOffer :key="job.id" v-for="job in jobs" :job="job" />
      </PrimeTabPanel>

      <PrimeTabPanel
        v-if="user?.zmidAssoc?.is_active"
        class="space-y-3"
        value="2"
      >
        <LazyAnnouncementCard
          :annoucement="item"
          :key="item.id"
          v-for="item in informations"
          type="information"
        />
      </PrimeTabPanel>
    </PrimeTabPanels>
  </PrimeTabs>
</template>
