<script setup lang="ts">
import type { News, Information } from '~/utils/types';
import { useQueryClient } from '@tanstack/vue-query';

const queryClient = useQueryClient();

const props = defineProps<{
  annoucement: News | Information;
  type: 'information' | 'news';
}>();

const VARIANTS: {
  [key: string]: { icon: string; class: string; iconBg: string };
} = {
  new_course: {
    icon: 'pi pi-book',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-primary',
  },
  discount: {
    icon: 'pi pi-ticket',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-green-500',
  },
  other: {
    icon: 'pi pi-star',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-yellow-500',
  },
  event: {
    icon: 'pi pi-star',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-yellow-500',
  },
  exam_news: {
    icon: 'pi pi-star',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-yellow-500',
  },
  default: {
    icon: 'pi pi-book',
    class: 'border-surface-border bg-surface-section',
    iconBg: 'bg-primary',
  },
};

const op = ref();

const { readEvent } = useReadEvent();

function readAnnoucement() {
  readEvent(
    {
      id: props.annoucement.id,
      type: props.type,
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [props.type] });
      },
    },
  );

  op.value.hide();
}

function getStylesVariant() {
  if (props.type === 'news' && props.annoucement.type) {
    return VARIANTS[props.annoucement.type];
  } else {
    return VARIANTS['default'];
  }
}
</script>

<template>
  <article
    class="flex flex-col gap-5 rounded-md border border-solid p-4 text-sm xl:flex-row"
    :class="[
      getStylesVariant().class,
      { '!border-blue-100 !bg-blue-50': !annoucement.seen },
    ]"
  >
    <div class="xl:order-0 order-1 flex flex-1 items-start gap-2">
      <div
        class="flex aspect-square w-6 shrink-0 items-center justify-center rounded-full"
        :class="getStylesVariant().iconBg"
      >
        <i
          class="text-xs text-primary-inverse"
          :class="getStylesVariant().icon"
        />
      </div>

      <div class="flex-1 space-y-1 text-bluegray-500">
        <div class="font-bold text-bluegray-900">
          {{ annoucement.title }}
        </div>

        <div v-if="annoucement.description" class="mt-1">
          <ExpandingContent
            :expandedText="annoucement.description"
            :maxChars="350"
          />
        </div>
      </div>
    </div>

    <div class="flex justify-between gap-4 xl:order-2">
      <div
        v-if="annoucement.created_at"
        class="flex items-center gap-1 text-bluegray-500"
      >
        <i class="pi pi-calendar w-6 shrink-0" />

        <span>
          {{ formatDateToPolishLong(annoucement.created_at) }}
        </span>
      </div>

      <PrimeButton
        class="h-6 !p-0"
        text
        severity="secondary"
        @click="(event: MouseEvent) => op.toggle(event)"
      >
        <i class="pi pi-ellipsis-v text-base text-bluegray-800" />
      </PrimeButton>

      <PrimeOverlayPanel ref="op">
        <div class="-mx-4 -my-1 flex w-60 flex-col gap-1">
          <PrimeButton
            class="w-full !justify-start !font-normal !text-global-textColor"
            text
            severity="secondary"
            @click.stop="readAnnoucement"
          >
            <i class="pi pi-check-square text-sm" />
            Oznacz jako przeczytane
          </PrimeButton>
        </div>
      </PrimeOverlayPanel>
    </div>
  </article>
</template>
