<script setup lang="ts">
import type { KeepWatchingData } from '~/utils/types';

const { isSidemenuOpen } = useSidemenuInjector();
const slider = ref();
const { watchAgainData } = useCourseOverview();

const BREAKPOINTS = {
  0: {
    itemsToShow: 1,
  },
  768: {
    itemsToShow: 2,
  },
  1280: {
    itemsToShow: 3,
  },
};
</script>

<template>
  <section v-if="watchAgainData?.length" class="space-y-4 sm:text-sm">
    <header class="flex items-center justify-between">
      <h2 class="text-xl font-semibold text-bluegray-900">Obejrzyj ponownie</h2>

      <LazyCarouselNavigation
        v-if="
          slider && watchAgainData.length > slider.el.data.config.itemsToShow
        "
        :goNext="slider.goNext"
        :goPrev="slider.goPrev"
      />
    </header>

    <AppCarousel
      ref="slider"
      class="-mx-4"
      :key="`${isSidemenuOpen}`"
      :slides="watchAgainData"
      :items-to-show="2"
      :breakpoints="BREAKPOINTS"
    >
      <template #slide="{ slide }">
        <CourseCompactCard :keepWatching="slide as KeepWatchingData" />
      </template>
    </AppCarousel>
  </section>
</template>
