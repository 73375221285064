import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { eventsService } from '~/utils/api/eventsService';
import type { News, Information, JobOffer } from '~/utils/types';

export function useReadEvent() {
  const queryClient = useQueryClient();

  const {
    mutate: readEvent,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: (payload: {
      type: 'information' | 'offer' | 'news';
      id: number;
    }) => eventsService.readEvent(payload),
    onMutate: (event) => {
      queryClient.setQueryData(
        [event.type],
        (data: JobOffer[] | News[] | Information[]) => {
          return data.map((item) => {
            if (item.id === event.id && !item.seen) {
              if (!item.seen) {
                return { ...item, seen: true };
              }
            }

            return item;
          });
        },
      );
    },
  });

  const { mutate: readAllEvents } = useMutation({
    mutationFn: (payload: {
      type: 'information' | 'offer' | 'news';
      events: JobOffer[] | News[] | Information[];
    }) => {
      const requests = payload.events.map((event) =>
        eventsService.readEvent({ type: payload.type, id: event.id }),
      );
      return Promise.all(requests);
    },
    onMutate: (event) => {
      queryClient.setQueryData(
        [event.type],
        (data: JobOffer[] | News[] | Information[]) => {
          return data.map((item) => {
            return { ...item, seen: true };
          });
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  return { readEvent, readAllEvents, isPending, isSuccess };
}
