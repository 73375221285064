<script setup lang="ts">
definePageMeta({
  layout: 'authorized',
  middleware: ['auth'],
});

useHead({
  title: 'Portal Kursanta ZMID',
});

const { data: schedules, isLoading: isSchedulesLoading } = useSchedules();
const { data: user } = useUser();
</script>

<template>
  <div>
    <div class="grid grid-cols-10 gap-8 pt-6">
      <div
        class="space-y-6"
        :class="{
          'order-2 col-span-10 lg:order-1 lg:col-span-6 xl:col-span-7':
            schedules?.length || isSchedulesLoading,
          'col-span-10': !schedules?.length,
        }"
      >
        <!-- <MembershipExpiryAlert /> -->
        <CoursesStartWatching />
        <CoursesKeepWatching />
        <CoursesWatchAgain />
        <AnnouncementsPanel />
      </div>

      <div
        v-if="schedules?.length || isSchedulesLoading"
        class="order-1 col-span-10 lg:order-2 lg:col-span-4 xl:col-span-3"
      >
        <section id="schedule" class="space-y-4">
          <header class="flex items-center justify-between">
            <h2 class="text-xl font-semibold text-bluegray-900">Harmonogram</h2>
          </header>

          <LazyScheduleList />
        </section>
      </div>
    </div>

    <LazySchedulesAnnouncementsOnboarding
      v-if="user && schedules?.length && !isSchedulesLoading"
    />
    <LazyAnnouncementsOnboarding v-else-if="user" />
  </div>
</template>
