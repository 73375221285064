<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel';

defineProps<{
  slides: Array<unknown>;
}>();

const carouselElement = ref();

function goNext() {
  carouselElement.value.next();
}

function goPrev() {
  carouselElement.value.prev();
}

const el = computed(() => {
  return carouselElement.value;
});

defineExpose({
  goNext,
  goPrev,
  el,
});
</script>

<template>
  <carousel ref="carouselElement">
    <slide class="group" v-for="(slide, index) in slides" :key="index">
      <slot name="slide" :slide="slide" />
    </slide>
  </carousel>
</template>
