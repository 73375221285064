<template>
  <div class="flex h-0 items-center">
    <span
      v-if="unseenCount"
      v-auto-animate
      class="inline-flex size-7 items-center justify-center rounded-full bg-primary p-0 text-center text-[13px] font-bold leading-none text-primary-inverse"
      @mouseenter="isBadgeHovered = true"
      @mouseleave="isBadgeHovered = false"
      @click.stop="handleReadAllEvents"
    >
      <div>
        <span v-if="!isBadgeHovered">{{ unseenCount }}</span>
        <i
          v-else
          class="pi pi-check-square pointer-events-none ml-[0.5px] size-full text-[15px]"
        />
      </div>
    </span>
  </div>
</template>

<script setup lang="ts">
const { readAllEvents } = useReadEvent();
import { useQueryClient } from '@tanstack/vue-query';

const queryClient = useQueryClient();

const props = defineProps<{
  events: News[] | JobOffer[] | Information[] | null;
  type: 'information' | 'offer' | 'news';
}>();

const isBadgeHovered = ref(false);

function getUnseenCount(items: { seen: boolean }[]) {
  return items.reduce((acc, item) => acc + (!item.seen ? 1 : 0), 0);
}

const unseenCount = computed(() => {
  return props.events ? getUnseenCount(props.events) : null;
});

function handleReadAllEvents() {
  if (props.type && props.events) {
    readAllEvents(
      { type: props.type, events: props.events },
      {
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: [props.type] });
        },
      },
    );
  }
}
</script>
