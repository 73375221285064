<script setup lang="ts">
const { startWatching } = useCourseOverview();
</script>

<template>
  <section v-if="startWatching" class="space-y-4 sm:text-sm">
    <header class="flex items-center justify-between">
      <h2 class="text-xl font-semibold text-bluegray-900">
        Rozpocznij oglądanie
      </h2>
    </header>

    <CourseDetailCard :keepWatching="startWatching">
      <template #button>
        <PrimeButton rounded>
          Rozpocznij <i class="pi pi-arrow-right" />
        </PrimeButton>
      </template>
    </CourseDetailCard>
  </section>
</template>
